.claim-information-wrapper {
    .card {
        .divider-name {
            color: #000;
            left: 10px;
            top: -12px;
            z-index: 2;
            font-size: 12px;
            padding: 3px 5px;
            position: absolute;
            background-color: #ffffff;
        }
    }
}

.media-connection-bttn {
    .form-check {
        padding: 0;
        position: relative;
        overflow: hidden;
      
        label {
            cursor: pointer;
            padding: 4px 10px;
            border-radius: 0.25rem;
            border: 1px solid #ccc;
        }
        input {
            margin: 0;
            padding: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            border-radius: 0;
            cursor: pointer;

            &:checked + label {
                color: #506fd9;
                border-color: #506fd9;
            } 
        }
       
    }
}


.decision-tree-form {
    padding: 15px;
    overflow-y: auto;
    height: calc(100vh - 90px);
}