.disposition-sticky-note {
    color:#000;
    display:block;
    width:100%;
    position: sticky;
    top: 0;
}

.card-one.case-decision-tree-card {
    height: calc(100vh - 360px);
}